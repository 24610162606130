const languageHelper = (country: string): string => {
  switch (country) {
    case 'Finland':
      return 'fi';

    case 'Latvia':
      return 'lv';

    case 'Lithuania':
      return 'lt';

    default:
      return 'en';
  }
};

export default languageHelper;
