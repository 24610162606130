export default class Customer {
  customerID: number;

  customerCode: string;

  customerName: string;

  constructor(customerID: number, customerCode: string, customerName: string) {
    this.customerID = customerID;
    this.customerCode = customerCode;
    this.customerName = customerName;
  }
}
