import { FC, useState, useContext } from 'react';
import { Box, Button, Center, Heading, Text, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { removePosDetails } from '../../../helpers/localStorageHelper';
import { UserContext } from '../../../context/UserContext';
import IPosDetails from '../../../models/posDetails';
import PosDetails from '../../components/PosDetails';
import { CartContext } from '../../../context/CartContext';

const Settings: FC = () => {
  const { state: user } = useContext(UserContext);
  const { state, dispatch } = useContext(UserContext);
  const { state: cart, dispatch: cartDispatch } = useContext(CartContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showNotAllowedError, setShowNotAllowedError] = useState(false);
  const [posDetails, setPosDetails] = useState<IPosDetails | null>(
    state.posDetails
  );
  const { t } = useTranslation();
  const history = useHistory();
  const toast = useToast({
    variant: 'subtle',
    isClosable: true,
    position: 'top',
  });

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      dispatch({
        type: 'SET_POS_DETAILS',
        posDetails,
      });
      cartDispatch({ type: 'CLEAR_CART', cart });
      history.push('/');
    } catch (e) {
      // activate submit button
      setIsLoading(false);
      // show user an error
      toast({
        status: 'error',
        title: t('details.failedToAddPosDetails'),
      });
    }
  };

  const handleQrResult = (result: string) => {
    if (posDetails) return;
    try {
      const data: IPosDetails = JSON.parse(result);
      if (
        data.posCode === undefined ||
        data.shopCode === undefined ||
        data.terminalCode === undefined
      ) {
        throw Error("QR codes data wasn't recognized");
      }

      const date: Date = new Date();
      date.setHours(date.getHours() + 12);
      //  date.setMinutes(date.getMinutes() + 2);
      data.expires = date.valueOf();

      setPosDetails(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      toast({
        status: 'error',
        title: t('details.incorrectCode'),
      });
      console.info("QR codes data wasn't recognized", error);
    }
  };

  const handleQrError = (error: string | DOMException) => {
    switch (error instanceof DOMException ? error.name : error) {
      default:
        break;
      case 'NotFoundException':
        break;
      case 'NotAllowedError':
        setShowNotAllowedError(true);
        break;
      case 'Permission denied':
        setShowNotAllowedError(true);
        break;
    }
  };

  const getQrReader = () => {
    if (showNotAllowedError)
      return (
        <Center pt="5">
          <Text color="red.500" m="auto" whiteSpace="pre-line">
            {t('details.cameraNotAllowed')}
          </Text>
        </Center>
      );
    return (
      <BarcodeScannerComponent
        delay={500}
        onUpdate={(_, result) => {
          if (result) {
            handleQrResult(result.getText());
          }
        }}
        onError={(error) => handleQrError(error)}
        videoConstraints={{ facingMode: 'environment' }}
      />
    );
  };

  if (
    user.posDetails != null &&
    new Date(user.posDetails.expires) < new Date()
  ) {
    setPosDetails(null);
    user.posDetails = null;
    removePosDetails();
    toast({
      status: 'error',
      title: t('details.rescan'),
    });
  }

  return (
    <Center w="full" px="5">
      <Box w={['lg', 'md', 'sm']} pt="10">
        <Heading as="h4" size="md" textAlign="center">
          {t('details.heading')}
        </Heading>
        <Text pt="1" textAlign="center" color="gray.400">
          {t('details.subHeading')}
        </Text>
        {posDetails === null && getQrReader()}
        {posDetails && <PosDetails posDetails={posDetails} />}
        <Button
          isLoading={isLoading}
          disabled={!posDetails}
          onClick={() => handleSubmit()}
          bg="gray.500"
          color="gray.50"
          mt="5"
          isFullWidth
          boxShadow="md"
        >
          {t('details.submit')}
        </Button>
        <Button
          isLoading={isLoading}
          disabled={!posDetails}
          onClick={() => setPosDetails(null)}
          bg="gray.500"
          color="gray.50"
          mt="5"
          isFullWidth
          boxShadow="md"
        >
          {t('details.rescanCode')}
        </Button>
      </Box>
    </Center>
  );
};

export default Settings;
