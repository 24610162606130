import { Box, Flex, Heading, useBreakpointValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FC, useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import UserButton from '../UserButton';

const AppHeader: FC = () => {
  const { state: userState } = useContext(UserContext);
  const { t } = useTranslation();
  const topPx = useBreakpointValue({ base: '1', md: '' });

  return (
    <Flex
      w="100%"
      flexDir="row"
      justifyContent="center"
      bgColor="gray.500"
      p="2"
      flex="0"
      boxShadow="md"
    >
      <Flex flex="1" justifyContent="center">
        <Heading color="gray.50" as="h1" size="lg">
          {t('appName')}
        </Heading>
      </Flex>
      <Box top={topPx} display="flex" position="absolute" right="0" pr="5px">
        {userState.operator !== null && <UserButton />}
      </Box>
    </Flex>
  );
};

export default AppHeader;
