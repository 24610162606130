import {
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FiDelete, FiLogOut, FiMenu } from 'react-icons/fi';
import { useMsal } from '@azure/msal-react';
import { UserContext } from '../../../context/UserContext';

const UserButton: FC = () => {
  const { state, dispatch } = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const { instance } = useMsal();

  const push = (url: string) => {
    history.push(url);
  };

  const editSettings = () => {
    push('/settings');
  };

  const invalidate = () => {
    push('/invalidate');
  };

  const signOut = () => {
    dispatch({ type: 'LOGOUT_USER' });
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  };

  return (
    <Menu>
      <MenuButton as={IconButton} icon={<FiMenu />} isRound />
      <MenuList>
        <MenuGroup title={state.operator?.operatorName}>
          {state.posDetails !== null && (
            <MenuItem icon={<EditIcon />} onClick={() => editSettings()}>
              {t('appHeader.edit')}
            </MenuItem>
          )}
          <MenuItem isDisabled icon={<FiDelete />} onClick={() => invalidate()}>
            {t('appHeader.invalidate')}
          </MenuItem>
          <MenuItem icon={<FiLogOut />} onClick={() => signOut()}>
            {t('appHeader.signOut')}
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};

export default UserButton;
