import { FC, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { Center, Flex, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { loginRequest } from '../../../authConfig';

const MsalLogin: FC = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();

  useEffect(() => {
    const doLogin = async () => {
      try {
        await instance.loginRedirect(loginRequest);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };
    doLogin();
  }, [instance]);

  return (
    <Flex
      h="full"
      w="full"
      bg="brand.primary"
      flexDir="column"
      justifyContent="center"
    >
      <Center>
        <Heading size="xl" color="brand.secondary">
          {t('login.redirect')}
        </Heading>
      </Center>
    </Flex>
  );
};

export default MsalLogin;
