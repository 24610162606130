import { FC, useState, useContext } from 'react';
import {
  Box,
  Button,
  Center,
  FormControl,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import Operator from '../../../models/operator';

const Login: FC = () => {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { state, dispatch } = useContext(UserContext);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowError(false);
    setIsLoading(true);
    try {
      // TODO: Replace mock login with actual login
      if (username.trim() === '' || password === '') {
        await Promise.reject(new Error('Incorrect username or password'));
      }
      // dispatch login
      dispatch({
        type: 'LOGIN_USER',
        operator: new Operator(username, 'test shop'),
      });
    } catch (e) {
      // activate submit button
      setIsLoading(false);
      // show user an error
      setShowError(true);
    }
  };

  if (state.operator !== null) {
    return <Redirect to="/" />;
  }
  return (
    <Center px="5" w="full">
      <Box w={['lg', 'md', 'sm']} pt="10">
        <form onSubmit={(e) => handleSubmit(e)}>
          <FormControl pt="2" id="username" isRequired>
            <Input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="username"
              placeholder={`${t('login.username')}*`}
            />
          </FormControl>
          <FormControl pt="2" id="password" isRequired>
            <InputGroup>
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                placeholder={`${t('login.password')}*`}
              />
              <InputRightElement>
                <IconButton
                  size="md"
                  aria-label="Toggle show password"
                  onClick={() => setShowPassword(!showPassword)}
                  variant="ghost"
                  icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Button
            isLoading={isLoading}
            bg="gray.500"
            color="gray.50"
            mt="5"
            type="submit"
            isFullWidth
            boxShadow="md"
          >
            {t('login.submit')}
          </Button>
          {showError && (
            <Center pt="5">
              <Text color="red.500" m="auto">
                {t('login.error')}
              </Text>
            </Center>
          )}
        </form>
      </Box>
    </Center>
  );
};

export default Login;
