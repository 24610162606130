import Customer from './customer';
import Operator from './operator';
import Parcello from './parcello';
import Payment from './payment';
import Product from './product';

export default class Cart {
  id: string;

  orderId: string;

  receiptNumber: number;

  storeCode: string;

  storeName: string;

  products: Array<Product>;

  payments: Array<Payment>;

  customer: Customer;

  operator: Operator;

  receiptInfo: string;

  parcello: Parcello;

  constructor(
    id: string,
    orderId: string,
    receiptNumber: number,
    storeCode: string,
    storeName: string,
    products: Array<Product>,
    payments: Array<Payment>,
    customer: Customer,
    operator: Operator,
    receiptInfo: string,
    parcello: Parcello
  ) {
    this.id = id;
    this.orderId = orderId;
    this.receiptNumber = receiptNumber;
    this.storeCode = storeCode;
    this.storeName = storeName;
    this.products = products;
    this.payments = payments;
    this.customer = customer;
    this.operator = operator;
    this.receiptInfo = receiptInfo;
    this.parcello = parcello;
  }

  getTotal = (): string => {
    let amount = 0;
    if (this.products.length > 0) {
      amount = this.products.map((x) => x.totalAmount).reduce((a, b) => a + b);
    }
    return amount.toFixed(2);
  };

  getProductCount = (): number => {
    if (this.products.length > 0) {
      return this.products.map((x) => x.quantity).reduce((a, b) => a + b);
    }
    return 0;
  };
}
