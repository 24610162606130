import { FC, useEffect, useState, useCallback, useContext } from 'react';
import {
  Button,
  Center,
  Flex,
  Heading,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { initializeCart } from '../../../helpers/api';
import IPosDetails from '../../../models/posDetails';
import { CartContext } from '../../../context/CartContext';
import { tokenRequest } from '../../../authConfig';

interface IShipmentCatalogProps {
  posDetails: IPosDetails;
}

const ShipmentCatalog: FC<IShipmentCatalogProps> = ({ posDetails }) => {
  const toast = useToast({ variant: 'subtle' });
  const { t } = useTranslation();
  const { state, dispatch } = useContext(CartContext);

  const [cartFailed, setCartFailed] = useState(false);
  const { instance } = useMsal();

  const doCartInitialization = useCallback(async () => {
    try {
      if (state.cart.id === '') {
        const result = await instance.acquireTokenSilent(tokenRequest);
        const cart = await initializeCart(posDetails, result.accessToken);
        dispatch({ type: 'INITIALIZE_CART', cart });
      }
      setCartFailed(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('App initialization failed', e);
      toast({
        title: t('catalog.productFailed'),
        status: 'error',
        isClosable: true,
      });
      setCartFailed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posDetails]);

  useEffect(() => {
    doCartInitialization();
  }, [doCartInitialization]);

  if (!cartFailed && state.cart.id !== '') {
    return (
      <Flex w="100%" flexDir="column">
        <Flex mb="5" w="100%" justifyContent="center">
          <Link
            to="/shipment"
            style={{ width: '100%', padding: '0 10px 0 10px' }}
          >
            <Button bgColor="gray.500" color="white" isFullWidth boxShadow="md">
              {t('shipment.heading')}
            </Button>
          </Link>
        </Flex>
      </Flex>
    );
  }

  if (cartFailed) {
    return (
      <Flex w="100%" flexDir="column">
        <Center>
          <Heading as="h4" size="md">
            {t('catalog.initializationFailedHeading')}
          </Heading>
        </Center>
        <Center>
          <Button onClick={() => doCartInitialization()}>
            {t('catalog.tryInitialization')}
          </Button>
        </Center>
      </Flex>
    );
  }

  return (
    <Center>
      <Spinner />
    </Center>
  );
};

export default ShipmentCatalog;
