export default class Parcello {
  paymentReference: string;

  popupText: string;

  quantity: number;

  shipmentDetails: string;

  trackingCode: string;

  constructor(
    paymentReference: string,
    popupText: string,
    quantity: number,
    shipmentDetails: string,
    trackingCode: string
  ) {
    this.paymentReference = paymentReference;
    this.popupText = popupText;
    this.quantity = quantity;
    this.shipmentDetails = shipmentDetails;
    this.trackingCode = trackingCode;
  }
}
