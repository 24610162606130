import { useMsal } from '@azure/msal-react';
import { Center, Flex, Heading, useToast } from '@chakra-ui/react';
import { FC, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import { tokenRequest } from '../../../authConfig';
import { UserContext } from '../../../context/UserContext';
import { getCart } from '../../../helpers/api';
import { setOrderIdToLocalStorage } from '../../../helpers/localStorageHelper';
import ShipmentCatalog from '../../components/ShipmentCatalog';

const Finalize: FC<{ cancellation: boolean }> = ({ cancellation }) => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { instance } = useMsal();
  const saveOrderId = async (cartId: string) => {
    const result = await instance.acquireTokenSilent(tokenRequest);
    const cartDetails = await getCart(cartId, result.accessToken);
    setOrderIdToLocalStorage(cartDetails.orderId);
  };

  const text: string = cancellation
    ? t('finalize.invalidationSuccess')
    : t('finalize.paymentSuccess');

  const { state: user } = useContext(UserContext);
  const toast = useToast({ variant: 'subtle' });

  useEffect(() => {
    saveOrderId(id);
    toast({
      title: text,
      status: 'success',
      isClosable: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (user.posDetails === null) {
    saveOrderId(id);
    return <Redirect to="/settings" />;
  }

  return (
    <Flex overflowY="auto" flex="1" flexDir="column">
      <Flex flexDir="column" mt="5">
        <ShipmentCatalog posDetails={user.posDetails} />
      </Flex>
    </Flex>
  );
};

export default Finalize;
