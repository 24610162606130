import { FC, useState, useContext } from 'react';
import { Box, Button, Center, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { Redirect, useHistory } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import { getOrderIdFromLocalStorage } from '../../../helpers/localStorageHelper';
import { receiptCancellation } from '../../../helpers/api';
import i18n from '../../../localization/i18n';
import { tokenRequest } from '../../../authConfig';

const Invalidate: FC = () => {
  const { state: user } = useContext(UserContext);
  const [isInvalidating, setIsInvalidating] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const toast = useToast({
    variant: 'subtle',
    isClosable: true,
    position: 'top',
  });

  const { instance } = useMsal();

  const doInvalidateReceipt = async () => {
    const orderId = getOrderIdFromLocalStorage();
    if (orderId === null) {
      toast({
        status: 'warning',
        title: t('details.noOrderId'),
      });
      return;
    }
    try {
      setIsInvalidating(true);
      const tokenResult = await instance.acquireTokenSilent(tokenRequest);
      const activeUser = instance.getActiveAccount()?.name;
      const paycoUrl = await receiptCancellation(
        orderId,
        activeUser !== undefined ? activeUser : '',
        i18n.language,
        tokenResult.accessToken
      );
      window.location.href = paycoUrl;
    } catch (e) {
      setIsInvalidating(false);
      toast({
        status: 'error',
        title: t('details.invalidationFailed'),
      });
    }
  };

  const back = () => {
    history.push('');
  };

  // redirect user to read qr code if pos settings are missing
  if (
    user.posDetails === null ||
    new Date(user.posDetails.expires) < new Date()
  ) {
    return <Redirect to="/settings" />;
  }

  return (
    <Center w="full" px="5">
      <Box w={['lg', 'md', 'sm']} pt="10">
        <Button
          disabled={isInvalidating}
          bg="gray.500"
          color="gray.50"
          isFullWidth
          mb="10"
          isLoading={isInvalidating}
          onClick={() => doInvalidateReceipt()}
        >
          {t('invalidate.invalidate')}
        </Button>
        <Button
          bg="gray.500"
          color="gray.50"
          isFullWidth
          mb="10"
          onClick={() => back()}
        >
          {t('invalidate.back')}
        </Button>
      </Box>
    </Center>
  );
};

export default Invalidate;
