import CatalogProduct from '../models/catalogProduct';
import Cart from '../models/cart';
import PaycoOrder from '../models/paycoOrder';
import PaycoSaleRow from '../models/paycoSaleRow';
import Product from '../models/product';
import IPosDetails from '../models/posDetails';
import PaymentTerminal from '../models/paymentTerminal';

interface ProductResponse {
  productID: number;
  productCode: string;
  productName: string;
  infoText: string;
  salePrice: number;
}

const apiUrl = process.env.REACT_APP_BASKET_URI || '';
const imageUrl = process.env.REACT_APP_BASKET_IMAGE_URI || '';
const paycoEnv = process.env.REACT_APP_PAYCOENV || '';
const env = process.env.REACT_APP_ENV || ''; // local, dev, test, prod
/*
// const apiUrl = 'https://wscore-posmanager-demo.azurewebsites.net/api';
const apiUrl = 'https://localhost:44305/api';
const imageUrl = 'https://stposmanagerdemo.blob.core.windows.net/images';
const paycoEnv = '';
const env = 'local';
*/
export const getCart = async (cartId: string, token: string): Promise<Cart> => {
  const response: Cart = await (
    await fetch(`${apiUrl}/cart/${cartId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();
  return response;
};

export const getPhoto = async (id: number): Promise<string> => {
  const response = await fetch(`https://picsum.photos/1024/576?random=${id}`);
  return response.url;
};

export const getProducts = async (
  shopCode: string,
  token: string
): Promise<Array<CatalogProduct>> => {
  const response: Array<ProductResponse> = await (
    await fetch(`${apiUrl}/search/${shopCode}/products`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();
  const products = Promise.all(
    response.map(async (x) => {
      // const imageUrl = await getPhoto(x.productID);
      return new CatalogProduct(
        x.productID,
        x.productCode,
        x.productName,
        x.infoText,
        x.salePrice,
        imageUrl.length === 0 ? '' : `${imageUrl}/${x.productCode}.png`
      );
    })
  );
  return products;
};

export const initializeCart = async (
  posDetails: IPosDetails,
  token: string
): Promise<string> => {
  const response = await fetch(`${apiUrl}/cart`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      shopCode: posDetails.shopCode,
      posNumber: +posDetails.posCode,
    }),
  });
  if (response.ok) {
    const cart = await response.json();
    return cart;
  }
  return Promise.reject(await response.json());
};

export const addCustomer = async (
  cartId: string,
  customerCode: string,
  token: string
): Promise<Cart> => {
  const response = await fetch(`${apiUrl}/cart/${cartId}/customer`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      customerCode,
    }),
  });
  if (response.ok) {
    const cart = await response.json();
    return cart;
  }
  return Promise.reject(await response.json());
};

export const addProductRow = async (
  cartId: string,
  productCode: string,
  quantity: number,
  token: string,
  salePrice?: number
): Promise<Cart> => {
  const response = await fetch(`${apiUrl}/cart/${cartId}/products`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      productCode,
      quantity,
      salePrice,
    }),
  });
  if (response.ok) {
    const cart = await response.json();
    return cart;
  }
  return Promise.reject(await response.json());
};

export const addReceiptInfo = async (
  cartId: string,
  receiptInfo: string,
  token: string
): Promise<Cart> => {
  const response = await fetch(`${apiUrl}/cart/${cartId}/receiptinfo`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      receiptInfo,
    }),
  });
  if (response.ok) {
    const cart = await response.json();
    return cart;
  }
  return Promise.reject(await response.json());
};

export const updateProductRow = async (
  cartId: string,
  productCode: string,
  quantity: number,
  token: string
): Promise<Cart> => {
  const response = await fetch(`${apiUrl}/cart/${cartId}/products`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      productCode,
      quantity,
    }),
  });
  if (response.ok) {
    const cart = await response.json();
    return cart;
  }
  return Promise.reject(await response.json());
};

export const deleteProductRow = async (
  cartId: string,
  productCode: string,
  token: string
): Promise<Cart> => {
  const response = await fetch(
    `${apiUrl}/cart/${cartId}/products/${productCode}`,
    {
      method: 'delete',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.ok) {
    const cart = await response.json();
    return cart;
  }
  return Promise.reject(await response.json());
};

const generatePaycoSaleRows = (
  productRows: Array<Product>
): Array<PaycoSaleRow> => {
  const rows = new Array<PaycoSaleRow>();
  productRows.forEach((x: Product) => {
    if (x.quantity > 1) {
      // for loop with quantity
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < x.quantity; i++) {
        rows.push(
          new PaycoSaleRow(
            x.productName,
            x.productInfo,
            x.infoText,
            Math.round(x.salePrice * 100)
          )
        );
      }
    } else {
      rows.push(
        new PaycoSaleRow(
          x.productName,
          x.productInfo,
          x.infoText,
          Math.round(x.salePrice * 100)
        )
      );
    }
  });
  return rows;
};

interface IPaycoData {
  cartId: string;
  customerCode?: string;
  terminalExternalData?: string;
  env: string;
}

const generateDataRow = (cart: Cart): string => {
  const data: IPaycoData = {
    cartId: cart.id,
    env: paycoEnv,
  };

  if (cart.customer !== null) {
    data.customerCode = cart.customer.customerCode;
  }

  if (cart.parcello.paymentReference !== null) {
    data.terminalExternalData = cart.parcello.paymentReference;
  }
  return JSON.stringify(data);
};

export const initializePayco = async (
  cart: Cart,
  posDetails: IPosDetails,
  language: string,
  token: string
): Promise<string> => {
  // map cart to payco object
  const paycoOrder = new PaycoOrder(
    '00000000-0000-0000-0000-000000000000',
    posDetails.shopCode,
    posDetails.posCode,
    cart.operator.operatorName,
    `${posDetails.shopCode}-${posDetails.posCode}`, // should be "shopcode-poscode"
    generateDataRow(cart),
    new PaymentTerminal('Nets', posDetails.terminalCode),
    generatePaycoSaleRows(cart.products)
  );
  // post payco object to integration api
  const response = await fetch(`${apiUrl}/payco/PaycoInitialization`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(paycoOrder),
  });
  if (response.ok) {
    const url = await response.text();
    return `${url}&lng=${language}`;
  }
  return Promise.reject(await response.json());
};

export const receiptCancellation = async (
  orderId: string,
  operatorName: string,
  language: string,
  token: string
): Promise<string> => {
  // post payco object to integration api
  const response = await fetch(
    `${apiUrl}/payco/PaycoCancellation?orderId=${orderId}&operatorCode=${operatorName}`,
    {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.ok) {
    const url = await response.text();
    return `${url}&lng=${language}`;
  }
  return Promise.reject(await response.json());
};

export const getReceipt = async (
  cartId: string,
  token: string
): Promise<string> => {
  const response = await fetch(
    `${apiUrl}/cart/${cartId}/receipt/download/html`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.ok) {
    const receipt = await response.text();
    return receipt;
  }
  return Promise.reject(await response.json());
};

export const addShipment = async (
  cartId: string,
  trackingCode: string,
  quantity = 1,
  token: string
): Promise<Cart> => {
  const response = await fetch(`${apiUrl}/cart/${cartId}/getshipment`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      trackingCode,
      quantity,
    }),
  });
  if (response.ok) {
    const cart = await response.json();
    return cart;
  }
  return Promise.reject(await response.json());
};

export const removeShipment = async (
  cartId: string,
  token: string
): Promise<Cart> => {
  const response = await fetch(`${apiUrl}/cart/${cartId}/products`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.ok) {
    const cart = await response.json();
    return cart;
  }
  return Promise.reject(await response.json());
};
