import { Flex } from '@chakra-ui/react';
import { FC, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import ShipmentCatalog from '../../components/ShipmentCatalog';

const Catalog: FC = () => {
  const { state: user } = useContext(UserContext);

  // redirect user to read qr code if pos settings are missing
  if (user.posDetails === null) {
    return <Redirect to="/settings" />;
  }

  return (
    <>
      <Flex overflowY="auto" flex="1" flexDir="column">
        <Flex flexDir="column" mt="5">
          <ShipmentCatalog posDetails={user.posDetails} />
        </Flex>
      </Flex>
    </>
  );
};

export default Catalog;
