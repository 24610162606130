import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ChakraProvider } from '@chakra-ui/react';
import './assets/styles/index.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import App from './views/App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './context/UserContext';
import { CartProvider } from './context/CartContext';
import { msalConfig } from './authConfig';

// import i18n
import './localization/i18n';

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
      <UserProvider>
        <CartProvider>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </CartProvider>
      </UserProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
