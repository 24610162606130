import * as React from 'react';
import Cart from '../../models/cart';
import CatalogProduct from '../../models/catalogProduct';
import Customer from '../../models/customer';
import Operator from '../../models/operator';
import Parcello from '../../models/parcello';
import Payment from '../../models/payment';
import Product from '../../models/product';

export interface ICartContext {
  showModal: boolean;
  selectedProduct: null | CatalogProduct;
  cart: Cart;
  showDrawer: boolean;
  paycoUrl: string;
  showPriceModal: boolean;
  showReceiptInfoModal: boolean;
}

export const initialState: ICartContext = {
  showModal: false,
  selectedProduct: null,
  cart: new Cart(
    '',
    '',
    -1,
    '',
    '',
    new Array<Product>(),
    new Array<Payment>(),
    new Customer(-1, '', ''),
    new Operator('', ''),
    '',
    new Parcello('', '', -1, '', '')
  ),
  showDrawer: false,
  paycoUrl: '',
  showPriceModal: false,
  showReceiptInfoModal: false,
};

export const CartContext = React.createContext<{
  state: ICartContext;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: ICartContext, action: any) => {
  // eslint-disable-next-line no-console
  console.log('action', action);
  switch (action.type) {
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
    case 'CLEAR_CART': {
      return {
        ...state,
        cart: new Cart(
          '',
          '',
          -1,
          '',
          '',
          new Array<Product>(),
          new Array<Payment>(),
          new Customer(-1, '', ''),
          new Operator('', ''),
          '',
          new Parcello('', '', -1, '', '')
        ),
        paycoUrl: '',
      };
    }
    case 'INITIALIZE_CART': {
      return {
        ...state,
        cart: new Cart(
          action.cart.id,
          '',
          -1,
          '',
          '',
          new Array<Product>(),
          new Array<Payment>(),
          new Customer(-1, '', ''),
          new Operator('', ''),
          '',
          new Parcello('', '', -1, '', '')
        ),
        paycoUrl: '',
      };
    }
    case 'OPEN_MODAL': {
      return {
        ...state,
        showModal: true,
        selectedProduct: action.product,
      };
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        showModal: false,
        selectedProduct: null,
      };
    }
    case 'OPEN_DRAWER': {
      return {
        ...state,
        showDrawer: true,
      };
    }
    case 'CLOSE_DRAWER': {
      return {
        ...state,
        showDrawer: false,
      };
    }
    case 'NAVIGATE_TO_CHECKOUT': {
      return {
        ...state,
        showDrawer: false,
        paycoUrl: action.paycoUrl,
      };
    }
    case 'NAVIGATE_TO_CATALOG': {
      return {
        ...state,
        paycoUrl: '',
      };
    }
    case 'UPDATE_CART': {
      return {
        ...state,
        cart: new Cart(
          action.cart.id,
          action.cart.orderId,
          action.cart.receiptNumber,
          action.cart.storeCode,
          action.cart.storeName,
          action.cart.products,
          action.cart.payments,
          action.cart.customer,
          action.cart.operator,
          action.cart.receiptInfo,
          action.cart.parcello
        ),
      };
    }
    case 'OPEN_PRICE_MODAL': {
      return {
        ...state,
        showPriceModal: true,
        selectedProduct: action.product,
      };
    }
    case 'CLOSE_PRICE_MODAL': {
      return {
        ...state,
        showPriceModal: false,
        selectedProduct: null,
      };
    }
    case 'OPEN_RECEIPTINFO_MODAL': {
      return {
        ...state,
        showReceiptInfoModal: true,
      };
    }
    case 'CLOSE_RECEIPTINFO_MODAL': {
      return {
        ...state,
        showReceiptInfoModal: false,
      };
    }
  }
};

export const CartProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
