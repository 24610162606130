import { FC, useState, useContext } from 'react';
import {
  Box,
  Button,
  Center,
  FormControl,
  Heading,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { Redirect } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { CartContext } from '../../../context/CartContext';
import {
  addShipment,
  initializePayco,
  removeShipment,
} from '../../../helpers/api';
import { UserContext } from '../../../context/UserContext';
import { tokenRequest } from '../../../authConfig';
import { IApiError } from '../../../models/apierror';
import i18n from '../../../localization/i18n';

const Shipment: FC = () => {
  const { state: user } = useContext(UserContext);
  const { state: cart, dispatch: cartDispatch } = useContext(CartContext);
  const [isSaving, setIsSaving] = useState(false);
  const [showIncorrectError, setShowIncorrectError] = useState(false);
  const [showNotAllowedError, setShowNotAllowedError] = useState(false);
  const [shipmentCode, setShipmentCode] = useState('');
  const { t } = useTranslation();
  const toast = useToast({
    variant: 'subtle',
    isClosable: true,
    position: 'top',
  });
  const { instance } = useMsal();

  const doCheckout = async () => {
    try {
      if (user.posDetails) {
        setIsSaving(true);
        const tokenResult = await instance.acquireTokenSilent(tokenRequest);
        const paycoUrl = await initializePayco(
          cart.cart,
          user.posDetails,
          i18n.language,
          tokenResult.accessToken
        );
        window.location.href = paycoUrl;
      }
    } catch (e) {
      setIsSaving(false);
      toast({
        title: t('shipment.checkoutError'),
        status: 'error',
      });
      // eslint-disable-next-line no-console
      console.error('Failed to initialize payco order', e);
    }
  };

  const addToCart = async () => {
    setIsSaving(true);
    try {
      const result = await instance.acquireTokenSilent(tokenRequest);
      const updatedCart = await addShipment(
        cart.cart.id,
        shipmentCode,
        1,
        result.accessToken
      );
      cartDispatch({ type: 'UPDATE_CART', cart: updatedCart });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (updatedCart.parcello.popupText) {
        toast({
          status: 'warning',
          title: updatedCart.parcello.popupText,
        });
      }
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error('Failed to add product', e);
      // activate submit button
      setIsSaving(false);
      // show user an error
      let errorMsg = t('shipment.failedToAddShipment');

      if (Array.isArray(e)) {
        setShipmentCode('');
        e.some((x: IApiError) => {
          errorMsg = `${x.message}`;
          return null;
        });
      }

      toast({
        status: 'error',
        title: errorMsg,
      });
    }
    setIsSaving(false);
  };

  const readCodeAgain = async () => {
    try {
      const result = await instance.acquireTokenSilent(tokenRequest);
      const updatedCart = await removeShipment(
        cart.cart.id,
        result.accessToken
      );
      cartDispatch({ type: 'UPDATE_CART', cart: updatedCart });
      setShipmentCode('');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error('Failed to add product', e);
      // activate submit button
      setIsSaving(false);
      // show user an error
      toast({
        status: 'error',
        title: t('shipment.failedToAddShipment'),
      });
    }
    setIsSaving(false);
  };

  const handleBarcodeResult = (result: string) => {
    if (shipmentCode) return;
    try {
      setShowIncorrectError(false);
      setShipmentCode(result);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.info("QR codes data wasn't recognized", error);
      setShowIncorrectError(true);
    }
  };

  const handleBarcodeError = (error: string | DOMException) => {
    switch (error instanceof DOMException ? error.name : error) {
      default:
        break;
      case 'NotFoundException':
        break;
      case 'NotAllowedError':
        setShowNotAllowedError(true);
        break;
      case 'Permission denied':
        setShowNotAllowedError(true);
        break;
    }
  };

  const getBarcodeReader = () => {
    if (showNotAllowedError)
      return (
        <Center pt="5">
          <Text color="red.500" m="auto" whiteSpace="pre-line">
            {t('shipment.cameraNotAllowed')}
          </Text>
        </Center>
      );
    return (
      <BarcodeScannerComponent
        delay={500}
        onUpdate={(_, result) => {
          if (result) {
            handleBarcodeResult(result.getText());
          }
        }}
        onError={(error) => handleBarcodeError(error)}
        videoConstraints={{ facingMode: 'environment' }}
      />
    );
  };

  // redirect user to read qr code if pos settings are missing
  if (
    user.posDetails === null ||
    new Date(user.posDetails.expires) < new Date()
  ) {
    return <Redirect to="/settings" />;
  }

  if (cart.cart.id === '') {
    return <Redirect to="/" />;
  }

  return (
    <Center w="full" px="5">
      <Box w={['lg', 'md', 'sm']} pt="2">
        <Heading as="h4" size="md" textAlign="center">
          {cart.cart.getProductCount() === 0 && t('shipment.heading')}
          {cart.cart.getProductCount() > 0 && t('shipment.qrDataHeading')}
        </Heading>
        <Text pt="1" textAlign="center" color="gray.400">
          {cart.cart.getProductCount() === 0 && t('shipment.subHeading')}
          {cart.cart.getProductCount() > 0 && <br />}
        </Text>
        {cart.cart.getProductCount() === 0 &&
          shipmentCode === '' &&
          getBarcodeReader()}
        {/* eslint-disable-next-line react/no-danger */}
        {cart.cart.getProductCount() > 0 && (
          <div
            dangerouslySetInnerHTML={{
              __html: cart.cart.parcello.shipmentDetails,
            }}
          />
        )}
        {showIncorrectError && (
          <Center pt="5">
            <Text color="red.500" m="auto" whiteSpace="pre-line">
              {t('shipment.incorrectCode')}
            </Text>
          </Center>
        )}
        {cart.cart.getProductCount() === 0 && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (shipmentCode === '') return;
              addToCart();
            }}
          >
            <FormControl pt="2" id="shipmentCode">
              <Input
                autoFocus
                autoComplete="off"
                value={shipmentCode}
                onChange={(e) => setShipmentCode(e.target.value)}
                type="text"
                placeholder={`${t('shipment.shipment')}`}
              />
            </FormControl>
          </form>
        )}
        {cart.cart.getProductCount() > 0 && (
          <Button
            isLoading={isSaving}
            onClick={() => doCheckout()}
            bg="gray.500"
            color="gray.50"
            mt="5"
            isFullWidth
            boxShadow="md"
          >
            {t('shipment.submit')}
          </Button>
        )}
        {cart.cart.getProductCount() === 0 && (
          <Button
            isLoading={isSaving}
            disabled={isSaving || (!isSaving && shipmentCode === '')}
            onClick={() => addToCart()}
            bg="gray.500"
            color="gray.50"
            mt="5"
            isFullWidth
            boxShadow="md"
          >
            {t('shipment.next')}
          </Button>
        )}
        {cart.cart.getProductCount() > 0 && (
          <Button
            isLoading={isSaving}
            onClick={() => readCodeAgain()}
            bg="gray.500"
            color="gray.50"
            mt="5"
            isFullWidth
            boxShadow="md"
          >
            {t('shipment.rescanCode')}
          </Button>
        )}
      </Box>
    </Center>
  );
};

export default Shipment;
